@media all and (max-width: 1280px) {
    .title-tab{
        .text{
            h5{font-size: 18px; margin-bottom: 5px; line-height: normal;}
            p{font-size: 1.5rem; line-height: normal}
        }
    }
}
@media all and (max-width: 1023px) {
    
}