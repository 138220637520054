.bg-sign{
    overflow:hidden;
    @include position(absolute,0 0 0 0);
    width:100%;
    z-index: 1;
    img{
        object-fit:cover;
        min-width:100%;
        min-height:100%;
        max-width:unset;
    }
}
.sign-layout{
    background-attachment:fixed;
    background-size:cover;
    min-height:100vh;
    .wrapper{
        position:relative;
        z-index: 2;
        padding:70px 0 100px;
        > .row{

        }
    }
    .caption{
        @include position(fixed,null null 115px 110px)
        width:40%;
        align-self:flex-end;color:#FFF;
        h2{
            text-transform:uppercase;
        }
        p{
            font-size:2.4rem;
            font-weight:300;
            line-height:1.4;
            margin:0;
        }
    }
    .form-login{
        width:470px;
        margin-left:auto;
        align-self:center;
        flex:unset;
        background:$color-primary;
        color:#FFF;
        padding:0 50px 60px;
        border-radius:20px;
        overflow:hidden;
        a{
            color:#86A2C9;
        }
        .forgot-link{
            text-align:right;
            a{
                font-size:1.4rem;
                transition: all .2s ease-out; 
                &:hover {
                    color:$color-initial; 
                }
            }
        }
    }
    .tab-login{
        display:flex;
        margin:0 -50px 50px;
        align-items:center;
        a{
            width:50%;
            height:90px;
            line-height:90px;
            font-size:2.2rem;
            color:#FFF;
            text-align:center;
            background:$color-tertiary;
            border-radius:0px 0px 40px 0px;
            * { opacity:0.4; }
            img{
                margin:0 5px -5px 0;
                position:relative;
            }
            &:nth-child(2){
                border-radius:0px 0px 0px 40px;
            }
            &.active{
                background:rgba(0,0,0,0);
                * { opacity:1;}
            }
        }
    }
    .foot{
        font-size:1.4rem;
        margin:90px 0 0 0 ;
        text-align:center;
    }

    .sign-radio{
        width:100%;
        height:160px;
        border-radius:10px;
        background:#FFF;
        border:4px solid transparent;
        position:relative;
        transition: all .2s ease-out; 
        input[type=radio]{
            opacity:0;
            position:absolute;
        }
        figure{
            width:60px;
            height: 60px;
            margin:32px auto 15px auto;
            background: rgb(167,167,167);
            background: -moz-linear-gradient(158deg, rgba(167,167,167,1) 0%, rgba(113,113,113,1) 100%);
            background: -webkit-linear-gradient(158deg, rgba(167,167,167,1) 0%, rgba(113,113,113,1) 100%);
            background: linear-gradient(158deg, rgba(167,167,167,1) 0%, rgba(113,113,113,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7a7a7",endColorstr="#717171",GradientType=1);
            border-radius:25px;
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
        }
        span{
            text-align:center;
            color:#666;
            display:block;

        }
        &:hover {
            border-color:$color-secondary;
            box-shadow:0px 5px 15px rgba(0,0,0,0.2);
        }
        &.active{
            border-color:$color-secondary;
            box-shadow:0px 5px 15px rgba(0,0,0,0.2);
            figure{
                background: rgb(123,179,63);
                background: -moz-linear-gradient(158deg, rgba(123,179,63,1) 0%, rgba(59,148,37,1) 100%);
                background: -webkit-linear-gradient(158deg, rgba(123,179,63,1) 0%, rgba(59,148,37,1) 100%);
                background: linear-gradient(158deg, rgba(123,179,63,1) 0%, rgba(59,148,37,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7bb33f",endColorstr="#3b9425",GradientType=1);
            }
        }
    }
    .title{
        width:auto;
        display:inline-block;
        padding:30px 60px;
        background: rgb(130,185,59);
        background: -moz-linear-gradient(135deg, rgba(130,185,59,1) 0%, rgba(8,132,57,1) 100%);
        background: -webkit-linear-gradient(135deg, rgba(130,185,59,1) 0%, rgba(8,132,57,1) 100%);
        background: linear-gradient(135deg, rgba(130,185,59,1) 0%, rgba(8,132,57,1) 100%);
        color:#FFF;
        border-radius:0px 0px 40px 0px;
        margin:0 -50px 50px;
        h4{display:inline;}
        img{
            margin:0 5px -5px 0;
            position:relative;
        }
    }
    p.forgot-text{
        color:#78A3DE;
        margin-bottom:40px;
        line-height:1.6;
    }
}

.wrap-popup.otp-popup{
    .box-popup{
        width:550px;
        text-align:center;
        h4{
            color:#3C3C3C;
            margin-bottom:10px;
        }
        button{
            width:300px;
        }
        .otp{
            display:flex;
            justify-content:space-around;
            margin: 50px 20px ;
            input{
                width:50px;
                height:60px;
                background:#EAEAEA;
                padding:0 10px;
                border-radius:10px;
                font-size:3.0rem;
                text-align:center;
            }

        }
    }
}