@media all and (max-width: 767px ) {
    .box-rounded{
        margin-bottom: 2rem;
        .title-box-rounded{
            padding: 1.5rem;
            .right-title{
                width: 20%; 
                a{font-size: 12px; line-height: 15px; display: block; font-weight: 700; text-transform: uppercase;}
            }
        }
        .content-box-rounded{
            padding: 2rem 1rem; position: relative;
            .row{
                margin-bottom: 10px;
                .column {
                    &.col-name {
                        position: relative; 
                        h5 {padding-right: 120px;}
                        .grey-text {padding-right: 120px;}
                        .button {
                            position: absolute; top: 0; right: 10px; bottom: 0;  
                            margin-bottom: auto; margin-top: auto;  
                        }
                    }
                }
                .column-50{
                    width: 100%; max-width: unset; padding: .8rem .5rem;
                    margin-bottom: 0;
                    .left-mob{float: left; margin-right: 1rem;}
                    .right-mob{float: right;}     
                    &:first-child {}
                    &:last-child {
                        
                    }               
                }
                &.row-line-space {
                    padding: 1rem; margin-bottom: 0;  .column {
                        border-right: 0; 
                    }
                    .column-50 {
                        padding: 1rem 1rem;
                    }
                }                
            }
            br {height: 0; display: none;}
            .scroll-box{
                min-height: 8.7rem; 
                margin-bottom:0px;
                p{font-size: 1.4rem; line-height: 2.0rem; margin-bottom: 1rem;}
            }
            table{
                tr{
                    td{font-size: 1.4rem;}
                }
            }
        }
        .note-progress {
            margin-bottom: -15px;
        }
        &.detail-kontrak {
            .title-box-rounded {
                display: block; 
            }
        }
    }
    .mCSB_inside > .mCSB_container {
        margin-right: 22px; 
    }
    .box-progress-rounded{
        .content-progress-rounded{
            padding: 1.5rem 2rem; display: block;
            .col{
                margin-right: 7%; width: 9%; display: inline-block; margin-bottom: 15px;
                &:nth-child(6n+6){margin-right: 0;}
                span{margin-bottom: 0; font-size: 1.3rem;}
            }
        }
    }
    .note-progress{
        > span{
            font-size: 1.2rem; margin-right: 0.5rem; display: inline;
        }
    }
    .slider-dashboard{
        .list-slide{
            figure{
                height: 280px;
                img{height: 100%; overflow: hidden; object-fit: cover;}
            }
            figcaption{
                width: auto; margin: 0 10px; padding: 1.5rem 3rem 4rem;
                font-size: 1.3rem; line-height: 1.3; 
                right: 10px;
            }
        }
        .slick-dots{
            right: 3.5rem;
            height: 1rem;
            bottom: 1.2rem;
        }
    }
    .accordion-button{
        font-size: 1.2rem;        
    }
    .side-img{
        min-height: unset;
        > img{width: 43px; top: 0; display: block;}
        .text-side-img{
            span{font-size: 12px;}
            h5{font-size: 14px;}
        }
    }
    /*.box-rounded .title-box-rounded{display: block;}*/
    .box-rounded {
        .title-box-rounded {
            .right-title{
                width: 100%;                
                margin-top: 15px; text-align: right;
                .button {
                    width: 100%; display: flex; justify-content: space-between; min-height: 45px; 
                    align-items: center; 
                    span {display: inline-flex; margin-bottom: 0;}
                    h5  {display: inline-flex;}
                }
            }
        }
        &.dashboard {
             .right-title{
                 width: 25%; 
             }
        }
    } 
    /* .detail-kontrak .title-box-rounded .left-title{width: 100%; margin-top: 15px; display: block;} */
    .box-rounded .title-box-rounded .left-title {
        justify-content: space-between; 
        .button{
            margin-left: 0; padding: 0.5rem 1.5rem; font-size: 1.5rem; line-height: 2.5rem; height: 35px; margin-top: 1.5rem; width: 80px;
        }
        .side-img {
            padding-left: 4.6rem;
            > img {
                margin-left: -4px;
            }
        }
    }
    .row-white-rounded > .column.column-33,.row-line-space > .column.column-50, .row-white-rounded > .column.column-66, .box-rounded .content-box-rounded .row .column.column-33{
        width: 100%; max-width: unset; margin-bottom: .8rem;
    }
    .row-white-rounded > .column > .inner-column h5{margin-bottom: 0; font-size: 1.6rem;}
    .row-white-rounded > .column > .inner-column br{display: none;}
    .wrap-table{overflow: scroll;}
    .row-white-rounded a.button{padding: 0.5rem 1.5rem; font-size: 1.5rem; line-height: 2.5rem; height: 35px; text-transform: unset; width: 100%;}
    .row-white-rounded > .column > .inner-column h6{margin-bottom: 0; font-size: 1.6rem;}
    .row-line-space > .column{padding-top: 1.5rem;}
    .row-white-rounded > .column > .inner-column{
        padding: 1.5rem;
        &::after{

            clear:both;display: block;content:" ";
        }
        .left-mob{float: left;}
        .right-mob{float: right;}
    }
    .box-rounded .content-box-rounded .row-bg-grey{
        .column{
            padding: 1.0rem 1.5rem; padding-bottom: 1rem;
            .side-img.v2 > img{
                margin: 0; width: 25px; margin-bottom: 1rem;
            }
            .text-side-img{margin-top: 5px;}
            .blue-text{margin-left: 3.0rem;}
            .side-img {
                &.v2 {
                    img {
                        margin-right: 0;
                    }
                }
            }
            .left-mob {margin-right: .5rem;}
            &:last-child{padding-top: 0;}
            &:first-child{padding-bottom: 0;}
        }
    }
    .text-row-value{
        width: 100%;
        .left-mob{float: left; width: 80%;}
        .right-mob{float: right; width: 20%; margin-top: 2.5rem;}
    }
    .custom-mobile{
        table {
            border: 0;
          }
        
          table caption {
            font-size: 1.3em;
          }
          
          table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
          
          table tr {
            display: block;
            padding: 2.5rem 0;
            &:nth-child(odd){background: #E6EBF5;}
            &:nth-child(even){background: #fff;}
          }
          
          table td {
            display: block;
            font-size: 1.5rem;
            font-weight: bold;
            text-align: right;
            padding: 0.5rem 1.5rem;
          }
          
          table td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            color: #818181; font-size: 1.4rem;
          }
          
          table td:last-child {
            border-bottom: 0;
          }
          table td,th{
              &:last-child{padding-right: 1.5rem;}
          }          
    }
    .wrap-table {
        &.blue-bordered {
            thead {
                display: none;
            }
        }
        &.table-head {
            margin-bottom: 0;
        }
    }
    .notif-inline {
        font-size: 1.3rem;
        padding-right: 6.2rem; 
    }
}