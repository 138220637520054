@media all and (max-width: 1280px) {
    header{
        &.dashboard{
            height: 120px;
            .logo{
                width: 200px;
                img{width: 123px;}
            }
            .right-header{
                width: calc(100% - 200px); padding:0 55px 0 77px;
            }
        }
    }
    .dashboard-content{
        aside{
            width: 230px; padding-left: 30px;
            .foot{
                h6{font-size: 1.5rem;}
            }
            nav{
                a{font-size: 1.4rem; margin-bottom: 1.5rem;}
            }
        }
        .content-right{
            width: calc(100% - 230px); padding: 58px 50px;
        }
    }
    .wrap-table{
        td,th{padding: 15px 10px; font-size: 1.3rem;}
        &.blue-bordered {
            max-height: 595px;
        }
    }
    .accordion{
        .list-accordion{
            h6{font-size: 1.6rem;}
        }
        .content-accordion{
            font-size: 1.5rem;
        }
    }
    .title-content{font-size: 1.8rem;}
    .wrap-tabing{
        .content-tab{padding: 35px 0 0;}
    }
    .box-white{
        padding: 4rem 5rem;
        h5{
            font-size: 1.8rem;
        }
        h6{font-size: 1.6rem; margin-bottom: 1rem;}
        label{margin-bottom: 0;}
        .result{
            padding: 35px;
        }
        .container {
            .row {
                padding-bottom: 10px; 
            }
        }
    }
}
@media all and (max-width: 1023px) {
    header{
        padding-left: 110px; box-sizing: border-box;
        &.dashboard{
            height: 9rem; position: fixed; width: 100%; background: #fff;
            .logo{
                width: 15rem;
                img{margin-top: 2rem;}
            }
            .right-header{
                width: calc(100% - 135px);
                h4{font-size: 1.5rem;}
            }
        }
        &.login{
            padding-left: 0; margin: 0 60px;
        }
    }
    .dashboard-content{
        aside{
            position: relative; width: 0; padding: 0;
            .burg_nav{
                display: block;position: fixed;width: 34px;text-align: right;cursor: pointer; float: right; cursor: pointer; left: 30px; z-index: 989;top:35px;
                span{
                    display: block;width: 100%;height: 4px;float: right;margin-bottom: 7px; border-radius: 20px;
                    background: rgb(130,185,59);
                    background: -moz-linear-gradient(135deg,  rgba(8,132,57,1) 0%, rgba(130,185,59,1) 100%);
                    background: -webkit-linear-gradient(135deg, rgba(8,132,57,1) 0%, rgba(130,185,59,1) 100%);
                    background: linear-gradient(135deg, rgba(8,132,57,1) 0%, rgba(130,185,59,1) 100%);
                }
            }
            &.bg{
                z-index: 899;
                .burg_nav{
                    left: 275px; top: 35px;
                    span{
                        &:nth-child(1){
                            position: absolute;
                            -webkit-transform: rotate(-135deg);
                            -moz-transform: rotate(-135deg);
                            -o-transform: rotate(-135deg);
                            transform: rotate(-135deg);
                        }
                        &:nth-child(2){
                            -webkit-transform: rotate(135deg);
                            -moz-transform: rotate(135deg);
                            -o-transform: rotate(135deg);
                            transform: rotate(135deg);
                        }
                        &:last-child{opacity: 0;}
                    }
                }
            }
            .menu-mobile{
                position: fixed; top: 0px; left: 0; right:auto; background: #fff; z-index: 899; display: none; height: 100%; width: 342px; box-sizing: border-box;  overflow: auto; margin-top: 0;
                nav{
                    a{
                        padding-left: 115px;
                        img{left: 70px;}
                        &.active,
                        &:hover{
                            &:before{
                                opacity: 1;
                                right:calc(100% - 110px);
                            }
                        }
                    }
                }
                .foot{
                    margin: 0 60px 65px;
                }
            }
            .account{
                margin: 60px 0 35px 0;
                .logo-mobile{
                    display: block; 
                    img{width: 123px; height: auto; margin-bottom: 3.5rem;}
                    
                }
                .link{font-size: 1.6rem;}
                figure{
                    height: 90px; width: 90px;
                }
            }
        }
        .content-right{
            width: 100%; padding: 0 30px; padding-top: 140px; box-sizing: border-box; margin-bottom: 55px;
        }
    }
    .nav-overlay{z-index: 8; background: #000; opacity: 0.5; position: fixed; height: 100%; width: 100%; left: 0; top: 0px;}
    .wrap-popup{
        .box-popup{
            margin: 15% 2rem; padding: 3rem;
            .container{padding: 0;}
            .row .column.column-50{width: 100%; max-width: unset;}
            label{font-size: 1.4rem;}
            h6{font-size: 1.6rem;}
        }
    }
}