@media all and (max-width: 767px ) {
    header{
        padding-left: 0;
        &.login{
            text-align: center; position: absolute;
            .logo{width: 128px;}
        }
        &.dashboard{
            height: 60px;
            .logo{
                width: 100%;
                img{height: 39px; width: auto; margin-top: 10px;}
            }
            .right-header{
                position: absolute; top: 60px; width: 100%; border-radius: 0 0 5rem 5rem; padding: 0 3rem;
                .lang{display: none;}
                h4{font-size: 15px; line-height: 25px; margin: 0 auto;padding: 1rem 0;}
            }
            .notifications{
                display: none; position: absolute; right: 0; top: 15px;
                &:before{color: #fff;}
                &.mobile-only{display: block;}
                .dropdown{
                    width: calc(100vw - 20px); right: -19px;
                    max-height: 478px; 
                }
            }
        }
    }
    .dashboard-content{
        aside{
            position: relative; width: 0; padding: 0;
            .burg_nav{
                top: 2rem;
            }
           
        }
        .content-right{
            width: 100%; padding: 0 30px; 
            /*padding-top: 165px; */
            padding-top: 125px; 
            box-sizing: border-box; margin-bottom: 55px;
        }
    }
    .nav-overlay{z-index: 8; background: #000; opacity: 0.5; position: fixed; height: 100%; width: 100%; left: 0; top: 0px;}
    .button.button-outline, button.button-outline, input[type='button'].button-outline, input[type='reset'].button-outline, input[type='submit'].button-outline{height: 40px; line-height: 33px;}
    .info-text{
        text-align: center;
        p{font-size: 1.5rem;}
    }
    .accordion{
        .list-accordion{
            margin-bottom: 1rem;
            .head-accordion{
                h6{font-size: 1.6rem;}
            }
        }
        .content-accordion{
            font-size: 1.5rem;
        }
    }
    .side-img{
        &.v2{
            display: block;
        }
    }
    .side-img.v2 .text-side-img span{font-size: 1.4rem; line-height: 15px;}
    .side-img.v2 > img{margin: 0 auto; margin-bottom: 5px; border-radius: 100%;}
    .row-claim{
        display: flex; text-align: center; flex-direction: row; 
        .column.column-25{            
            width: 25%; vertical-align: text-top; text-align: center; max-width: 25%; margin-bottom: 1rem;
        }
        .column {
            .side-img {
                .text-side-img {
                    span {font-size: 1.2rem;}
                }
                &.v2 {
                    > div {
                        justify-content: center;
                    }
                }
            }
            &.active {}
        }
    }
    .box-white{
        padding: 2rem;
        .container{padding: 0;}
        .row{margin-bottom: 0;}
        .row .column.column-50,.row .column.column-40{width: 100%; max-width: unset;}
        h5{font-size: 1.6rem;}
        h6{font-size: 1.5rem;}
        .result{
            padding: 3rem 2rem; margin: 0 0 10px 0; border-radius: 0;
            h5 b{color: #fff;}
            .row{margin-bottom: 0;}
            .column{
                margin-bottom: 1rem;
                &.column-30.column-offset-20{width: 100%; max-width: unset; margin-left: 0;}
            }
        }
        .result.form {
            label {margin-bottom: 5px;}
        }

        /* .form {
            .row {
                padding-bottom: 1rem; flex-direction: row;
                .column {
                    h6 {margin: 0;}
                    &.column-50 {
                        width: 60%; max-width: 60%;
                        flex: 0 0 60%;
                        margin:0 0 1rem 0;
                        &:nth-child(2) {
                            width: 40%; max-width: 40%;
                            flex: 0 0 40%;
                        } 
                    }
                }
            }
        } */
    }
    .form {
        label {
            &.info {padding-left: 0;}
        }
        .row {
            .column{
                margin: 1rem 0;        
            }
        }
    }     
    
    .wrap-tabing .content-tab{
        padding: 25px 0px 0px;
    }
    .box-white.nobottom-radius{
        .container.form{
            padding: 0;
        }
    }
    .form.bg-blue{
        padding: 3rem 2rem;
        .row{margin-bottom: 1rem;}
        .row .column.column-50,.row .column.column-40{width: 100%; max-width: unset;} 
    }
    .date-text{margin-bottom: 1rem;}
    .grey-text{line-height: normal;}
    
    input[type='email'], input[type='number'], input[type='password'], input[type='search'], input[type='tel'], input[type='text'], input[type='url'], textarea, select {
        height: 40px; 
        padding: .6rem 15px;  
    }
    .input-file, .multiple-upload {height: 40px !important;}
    .input-file > span, .multiple-upload > span {height: 40px; line-height: 40px;}
    .wrap-table {
        /* &.custom-mobile {
            .action-table {
                &:before {display:none;} 
            }
        } */
        &.blue-bordered {
            height: auto; max-height: none;    
            tbody {
                tr {
                    &:last-child {padding-bottom:0;} 
                    &.active {
                        border:2px solid #2E6CC3;
                        td {
                            border:0; 
                            &:first-child {
                                border-left:0;
                            }
                            &:last-child {
                                &:after {display:none;} 
                            }
                        }
                    }
                }
            }
        }
    }
    .to {
        font-size: 1.4rem; position: relative; 
        width: 100%; display: block;
        left: 0; margin-left: 0; bottom: 0;
        &:before {display:none;} 
    }
    .startdate {
        .column {
            &:last-child {
                margin-top:0;
                label {display:none;} 
            }    
        }        
    }
    .lang-mobile {
        display: block;
        margin: 0 60px 65px 60px; 
        a {
            color: #757575; 
            padding-right: 1px; font-size: 1.4rem;
            &.active {
                color: #023880; font-weight: bold; 
            }
            &:last-child {padding-left: 1px; padding-right: 0;} 
        }
    }
    .row-success {
        width: auto; 
        figure {width: 75%; margin: 0 auto 20px auto;} 
    }
    .floating-notif {
        right: 10px; bottom: 25px; 
        .notif-icon {
            width: 60px; height: 61px; 
            background-size: 100%; 
            span {
                font-size: 11px; padding-top: 20px; 
            }
        } 
        .notif-text {
            width: 345px; max-height: 360px; 
            bottom: 75px; 
            right: 5px !important; 
            padding: 35px 0px 35px 25px; 
            &:after {
                display:none; 
            }
            .wrap-table {
                td {
                    padding: 7px 5px;
                }
            }
        }
    }
    input.password,
    input[type='password'] {
        &.error {
            background-position:calc(100% - 42px);
        }
    }

}

@media all and (max-width: 463px ) {
    .popup{
        .popup-inner{
            width: auto;
            border-radius: unset;
            .top-side {
                padding: 30px 0;
                border-top-left-radius: unset;
                border-top-right-radius: unset;
            }
            .bottom-side {
                padding: 25px 30px;
            }
        }
    }
}

@media all and (max-width: 350px ) {
    .floating-notif {  
        .notif-text {
            width: 310px;   
            right: -5px !important;       
            padding: 35px 0px 35px 15px;   
        }
    }

}
 
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 560px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    header.dashboard .notifications .dropdown {
        max-height: 235px;
    }
    .floating-notif {
        .notif-text {
            right: 75px !important; bottom: 0px; 
            max-height: 275px; padding-top: 20px; padding-bottom: 20px;  
        }
    }
    .box-rounded {
        .content-box-rounded {
            .scroll-box {
                min-height: 9rem; 
                height: 9rem;
            }
        }
    }
    input.password,
    input[type='password'] {
        &.error {
            background-position:calc(100% - 45px);
        }
    }
}
