@media all and (max-width: 1600px) {
	html {		
		font-size: 56.25%;
	}
	body {
		img {
			transform: scale(.8);
		}		
	}	

	header {
		&.login {
			.logo {
				img {
					transform: scale(.9);
				}
			}
		}
		&.head-misc {
			padding-top: 50px; 
		}
	}
	.content-right {
		figure {
			transform: scale(1);
		}
	}
	.slick-slider {
		img {
			transform: scale(1); 
		}
	}
	.sign-layout {
		.wrapper {
			padding-top: 35px;
			padding-bottom: 35px;
		}
	}
	.misc-layout {
		figure {
			margin-top: -50px;
			img {} 
		}
	}
	.wrap-table {
		font-size:1.48rem;
		&.blue-bordered {			
			max-height: 750px; 
		}
	}
	h5 {font-size: 1.9rem;}
	.dashboard-content {
		background: url(../images/material/bg-dashboard-desktop.jpg) no-repeat center bottom;
	}
	.row-success {
		width: 615px; 
		figure {
			img {
				transform: scale(1); 
			}
		}
	}
}