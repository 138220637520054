.title-tab{
    display: flex;
    margin-bottom: 20px;
    figure{
        padding:8px 0 0px;
        margin: 0px;
        flex: 1 0 40px;
        max-width: 40px;
        margin-right: 20px;
    }
    .text{
        h5{
            font-weight: bold;
            color: #333333;
            margin-bottom: 1.5rem;
        }
    }
}
.max-limit{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 35px;
    border-bottom: 1px solid #E5E5E5;
    h5{
        color: #023880;
        font-weight: bold;
        margin-left: 30px;
        margin-bottom: 0;
    }
    h6{
        color: #888888;
        font-weight: 400;
    }
}
