/* header
----------------------------------------------------------------------------------------------*/
header.login{
    @include position(fixed,0 0 0 0);
    z-index: 2;
    .logo{
        display:inline-block;
        margin:45px 0 0 0;
    }
}

header.dashboard{
    position: relative;
    height:140px;
    display:flex;
    z-index:899;
    .logo{
        width:320px;
        text-align:center;
        img{
            margin:40px 0 0;
        }
    }
    .right-header{
        background:url(../images/material/head-dashboard.jpg) no-repeat left;
        background-size:cover;
        border-radius:0 0 0 100px;
        width:calc(100% - 320px);
        display:flex;
        align-items:center;
        padding:0 160px 0 120px;
        color:#FFF;
        h4{
            margin:0;
            text-transform:uppercase;
        }
        .lang{
            height:20px;
            line-height:20px;
            border-left:1px solid #B2B2B2;
            padding-left:30px;
            a{
                font-size:1.4rem;
                color:#FFF;
                padding:0px 5px;
                &:hover,
                &.active{
                    font-weight:bold;
                }
            }
        }
    }
    .notifications{
        padding:5px 0;
        margin: 0 30px 0 auto;
        position:relative;
        cursor: pointer;
        &:before{
            content: attr(data-count);
            @include position(absolute,3px -4px null null);
            width:18px;
            height:18px;
            background:#B20000;
            font-size:.9rem;
            border-radius:18px;
            text-align:center;
            line-height:18px;
        }
        &.mobile-only{display: none;}
        .dropdown{
            background:#FFF;
            padding:30px 5px 30px 30px;
            display:none;
            @include position(absolute,100% -20px null null);
            width:360px;
            border-radius:2rem;
            right:-20px;
            top:100%;
            max-height: 578px; 
            a{
                display:block;
                border-bottom:1px solid #EAEAEA;
                margin-bottom:2.15rem;
                padding-bottom:2.15rem;
                font-size:14px;
                font-weight:bold;
                color:#ADADAD;

                span{
                    background:url(../images/material/ico-time.svg) no-repeat left center;
                    font-size:1.3rem;
                    color:#888888;
                    display:block;
                    padding:0 0 0px 22px;
                    margin-bottom:10px;
                    font-weight:normal;
                }
                &.active{
                    color:#333;
                }
                &:last-child{
                    margin-bottom:0;
                    padding-bottom:0;
                    border:none;
                }
                &:hover{
                    span{color:$color-secondary}
                    color:$color-primary;
                }
            }
        }
        z-index:8;
    }
    &.act {
        z-index: 999;
    }
}
.lang-mobile {
    display: none;
}


/* content
----------------------------------------------------------------------------------------------*/
.overlay{
    background:rgba(0,0,0,0.8);
    @include position(fixed,0 0 0 0);
    z-index:7;
    display:none;
}
.dashboard-content{
    min-height:calc(100vh - 140px);
    background:url(../images/material/bg-dashboard.jpg) no-repeat center bottom;
    background-size:100% auto;
    display:flex;
    position: relative;
    aside{
        width:320px;
        padding-left:70px;

        .account{
            text-align:center;
            margin:20px 0 50px -70px;
            .logo-mobile{display: none;}
            figure{
                position:relative;
                border-radius:100%;
                width:100px;
                height:100px;
                margin:0 auto 10px;
                overflow:hidden;
                img{display:block;}
                &:hover > .change-picture{
                    visibility:visible;
                    opacity:1;
                }
            }
            .link{
                display:inline-block;
                font-size:1.7rem;
                color:#333333;
                padding-right:30px;
                background:url(../images/material/ico-arrow-right.svg) no-repeat 95% center;
                margin-bottom:20px;
            }
            .change-picture{
                @include position(absolute,0 0 0 0);
                visibility:hidden;
                opacity:0;
                width:100%;
                height:100%;
                background:rgba(0,0,0,0.6);
                transition:0.2s ease-out all;
                &:before{
                    content:"";
                    width:30px;
                    height:30px;
                    background:url(../images/material/ico-camera.svg) no-repeat center;
                    @include position(absolute,0 0 0 0);
                    margin:auto;
                }
                input{
                    width:100%;
                    height:100%;
                    opacity:0;
                }
            }
        }

        nav{
            margin-bottom:50px;
            a{
                position:relative;
                display:block;
                padding-left:50px;
                margin-bottom:20px;
                color:#5C5C5C;
                transition:0.2s ease-out all;
                height:40px;
                padding-top:10px;
                padding-bottom:10px;
                img{
                    @include position(absolute,0 null 0 0);
                    margin:auto;
                    filter:invert(1);
                    opacity: 0.2;
                    z-index:2;
                }
                &:before{
                    content:"";
                    background:$color-secondary;
                    width:110px;
                    height:40px;
                    border-radius: 0 50px 50px 0;
                    @include position(absolute,0 100% 0 null);
                    margin:auto;
                    opacity: 0;
                    transition:0.2s ease-out all;
                }
                &.active,
                &:hover{                    
                    &:before{
                        opacity: 1;
                        right:calc(100% - 40px);
                    }
                    img{
                        filter:invert(0);
                        opacity:0.45;
                    }
                }
                &.active {font-weight: 600;} 
            }
        }
        .foot{
            h6{
                color:$color-secondary;
                font-weight:500;
            }
        }
        .contact{
            margin-bottom:30px;
            a{
                padding-left:35px; min-height: 25px;
                position:relative;
                display:block;
                margin-bottom:20px;
                color:#666;
                img{
                    @include position(absolute,0 null 0 0px);
                    margin:auto;
                    transition: all .2s ease-out; 
                }
                &:last-child {
                    img {width:25px; height: 25px;}
                }
                &:hover {
                    font-weight: bold; 
                    img {
                        transform: scale(1.01); 
                    }
                }
            }
        }
        .copy{
            font-size:13px;
            color:#898989;
        }
    }
    .content-right{
        width:calc(100% - 320px);
        padding:40px 140px 100px 120px;
    }
}


.wrap-popup{
    @include position(fixed,0 0 0 0);
    z-index:9990;
    background:rgba(0,0,0,0.6);
    overflow:auto;
    display:none;
    .box-popup{
        background:#FFF;
        border-radius:20px;
        margin:5% auto;
        padding:60px;
        box-sizing:border-box;
        max-width:800px;
        position: relative;
    }
    .close{
        position: absolute; right: 25px; top: -35px; width:20px; height: 20px; cursor: pointer;
        z-index: 8; cursor: pointer;@include borderRadius(100%);
        &:after,&:before{
            content:''; width: 25px; height: 2px; background:#fff; display: block; @include transform(rotate(45deg)); top: 15px;
            position: absolute; left: 0; right: 0; margin: auto; @include borderRadius(20px);
            @include transition(.3s all ease-out);
        }
        &:before{  @include transform(rotate(-45deg));}
        &:hover{
            &:after{ @include transform(rotate(-45deg)); }
            &:before{ @include transform(rotate(-135deg)); }
        }
    }
    &.pelunasan{
        .content-center{
            text-align:center;
            h5{
                color:#111;
                font-weight:bold;
                margin-bottom:15px;
            }
            figure{
                margin:30px 0 40px;
            }
            p{
                margin-bottom:0;
            }
        }
    }
}

.baru {
    display: block;
}

.popup{
	position: fixed; 
	width: 100%; 
	height: 100%; 
	top: 0; 
	right: 0; 
	bottom: 0;  
	left: 0; 
    background: #000000c7; 
    z-index: 9999;
    display: flex;
    .popup-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .popup-inner{
        width: 340px; 
        background: #fff;
        margin:auto;
        padding: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 20px;
        position: relative;
        .btn-x {
            position: absolute; 
            width: 24px; 
            height: 24px; 
            top: -35px;
            right: 11px;         
            background: url('../images/material/close.png') no-repeat center center; 
            cursor: pointer;
            transition: all .3s ease-out;
            z-index: 9;
            &:hover {
                transform: rotate(90deg);
            }
        }
        .top-side {
            padding: 20px 0;
            text-align: center;
            background: #F2CE52;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        .bottom-side {
            padding: 25px 30px;
        }
    }
    .title-popup{
        position: relative;
        margin-bottom: 20px;
        h5{
            font-weight: bold;
            color: #35383B;
            margin-bottom: 0;
            text-align: center;
            font-size: 16px;
        }
    }
    .text{
        text-align: center;
    }
}

.accordion{
    .list-accordion{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom:0px;
        }
        &.active,&:hover{
            .head-accordion{
                background:#1150A7;
                h6{ color: #fff; }
            }
             .plus{
                span{
                    @include transform(rotate(-25deg));
                    &:first-child{
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
    }
    .head-accordion{
        padding: 20px 30px;
        box-sizing: border-box;
        background: #E7ECF2;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: .2s all ease-out;
        h6{
            margin-bottom: 0px;
            color: #555555;
            font-weight: 400;
        }
        .plus{
            position: absolute;
            right: 20px;
            top: 0;
            margin: auto 0;
            bottom: 0;
            width: 10px;
            height: 10px;
            transition: .2s all ease-out;
            span{
                width:100%;
                height:3px;
                background:#0CC154;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                @include transition(.25s all ease-out);
                @include transform(rotate(0deg));
                &:first-child{
                    @include transform(rotate(90deg));
                }
            }
        }
    }
    .content-accordion{
        padding: 30px;
        box-sizing: border-box;
        display: none;
    }
}
.wrap-tabing{
    .head-tab{
        ul{
            display: flex;
            width: 100%;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0px;
            li{
                display: inline-block;
                background: #EBEBEB;
                padding: 10px 40px;
                box-sizing: border-box;
                border-radius: 20px 20px 0px 0px;
                margin: 0px;
                transition: .2s all ease-out;
                cursor: pointer;
                &.active,&:hover{
                    background:#088439;
                    a{
                        color: #fff;
                    }
                }
                a{
                    background: transparent;
                    color: #5C5C5C;
                    display: block;
                }
            }
        }
    }
    .content-tab{
        padding: 50px 0px 0px;
        display: none;
    }
}
.note { font-size: 14px; font-style: italic; width: 70%; }
.form label.checkbox.info { padding-left: 0; }
.note-file{
    display: block;
    color: #9DB7DB;
    font-size: 1.3rem;
    margin-top: .5rem;
}
.box-white{
    padding: 45px 50px 20px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #EDEDED;
    border-radius: 20px;
    h6{ color: #333333; margin-bottom: 0;}
    input[type="text"]{
        background: #F7F7F7;
    }
    select{
        padding-right: 30px;
        background: #F7F7F7 url('../images/material/arr-select.png')no-repeat 95% center;
    }
    .error{
        display: block;
        color: #BF0000;
        font-weight: 500;
        font-size: 1.3rem;
        margin-top: 10px;
    }
    .container {
        .row {
            padding-bottom: 20px; 
        }
    }

    .result{
        padding: 35px 55px;
        box-sizing: border-box;
        background: #1150A7;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 20px;
        h6{
            margin-bottom: 0px;
            color: #fff;
            b {
                color: #fff; 
            }
        }
        h5 {
            b {
                color: #fff; 
            }
        }
        .row{
            margin-bottom: 10px;
        }
    }
    &.nobottom-radius{
        border-radius: 20px 20px 0px 0px;
    }
}

.info-size{
    display: block;
    font-size: 1.3rem;
    color: #9DB7DB;
}

.nomargin{
    margin: 0px !important;
}
.result-upload{
    display: block;
    background: #043476;
    border-radius:60px;
    border: 1px solid transparent;
    font-size:1.6rem;
    height: 4.5rem;
    line-height: 4.3rem;
    color:$color-initial;
    padding: 0 6rem 0 7rem;
    box-sizing: border-box;
    overflow-y: hidden;
    position: relative;

    &:before{
        content:"";
        background: url('../images/material/icon-picture.svg')no-repeat center;
        width: 2.4rem;
        @include position(absolute, 0 null 0 3rem);
    }
    .remove-input{
        width: 2.4rem;
        cursor: pointer;
        @include position(absolute, 0 2rem 0 null);

        &:before, &:after{
            content:"";
            background: #5F86BC;
            height: .1rem;
            width: 100%;
            @include transform(rotate(45deg));
            @include position(absolute, 50% 0 null null);
        }
        &:after{
            @include transform(rotate(-45deg));
        }
    }
}

.aside-radio{
    &.v2 {
        label.radio { cursor: pointer;
            input[type=radio] { border: 1px solid $color-primary; border-radius: 50%; top: 3px; 
                &:checked { background-position: -17px -1px; }
            }
        }
    }
    label.radio{
        display: inline-block;
        margin-right: 5rem;

        &:last-child{
            margin-right: 0;
        }
    }
}
.text-wrapper{
    .center{
        text-align: center;
    }
}
.form-sukses{
    padding-top:50px; 
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 45%;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    background: #FFF;
    border-radius: 0px;
    height: 25px;
    margin: 0 4px;
    -webkit-appearance: menulist;
    text-transform: uppercase;
}

.to {
    position: absolute; bottom:25px; left: 100%;
    margin-left: -6px; 
    font-size: 0;
    &:before {
        position:absolute; width: 10px; height: 1px; content: ""; 
        background: #fff; top: 0; left:0;
    }
}
.row-check {
    padding: 20px 0; 
}
.row-success{
    width: 705px; margin: auto;  
    padding-top: 60px; text-align: center;
    figure {
        margin-bottom: 50px;
    }
    h6 {color: #000;}  
    p{ margin-bottom: 5px; }
}
.floating-notif {
    position: fixed; right: 34px; bottom: 70px;  
    z-index: 888; 
    width: 70px; height: 71px;
    .notif-icon {
        width: 70px; height: 71px; display: flex; align-items: center; justify-content: center; 
        text-align: center; cursor: pointer; z-index: 3;
        background: url('../images/material/icon-lonceng.png') no-repeat 0 0; 
        position: absolute; right: 0; bottom: 0; 
        span {
            display: block; font-size: 13px; font-weight: bold; color: #000; 
            line-height: 1.2; padding-top: 22px; 
        }
    } 
    .notif-text {
        width: 520px; max-height: 465px; z-index: 3; 
        position: absolute; background: #1D4C8D; opacity: 0.95;
        right: 75px; bottom: -25px; padding: 35px 0px 35px 40px;    
        box-sizing: border-box; color: #fff;  
        border-radius: 20px; 
        visibility: hidden;        
        /*transition: all .4 ease-out; */
        h6 {margin-bottom: 10px;}
        p {margin-bottom: 20px; color: #E6E6E6;}  
        &:after {
            position:absolute; width: 11px; height: 16px; content: ""; 
            background: url('../images/material/icon-arrow-float.png') no-repeat 0 0; 
            bottom: 45px; right: -11px;  
        }
        .wrap-table {
            table {margin-bottom: 0;} 
            tr {
                &:first-child { 
                    td {
                        padding-top:0; 
                    }
                }
                &:nth-child(even) {
                    background: unset; 
                }
                &:last-child {
                    td {
                        padding-bottom: 0; 
                    }
                }
            }
            td {
                color: #fff; padding:10px 15px; 
                span {
                    display: block; 
                    &.cyan {color: #DBEAFF;}
                }
                a {
                    color: #00B748; font-weight: bold; 
                    &:hover {color:#09E25E;} 
                }
                &.td-btn {
                    vertical-align: bottom; 
                }
                &:first-child {padding-left:0;}
                &:last-child {padding-right: 0;} 
            }
        }        
    }
    .overlay-transparent {
        position: fixed; width: 100%; height: 100%; top: 0; left: 0; 
        background: transparent; z-index: 1;  cursor: default;
        display: none; 
    }
    &.act {
        z-index: 9999;
        /* .notif-text {
            visibility: visible;
            opacity: 1;
            right: 95px; 
        } */
    }
}

body.bg {overflow: hidden;}



/* footer
----------------------------------------------------------------------------------------------*/