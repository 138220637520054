/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import "helper/sass-lib";
@import "helper/flexbox";

/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general"; 
@import "pages/login";
@import "pages/topup";
@import "pages/dashboard";
@import "pages/account";
@import "pages/misc";  

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/login";
@import "pages_tablet/dashboard";
@import "pages_tablet/account";
@import "pages_tablet/topup";
@import "pages_tablet/misc";

/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/login";
@import "pages_mobile/dashboard";
@import "pages_mobile/account";
@import "pages_mobile/topup";