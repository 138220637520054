.box-rounded{
	border:.1rem solid #EDEDED;
	margin-bottom: 4.5rem;
	@include borderRadius(2rem);

	.title-box-rounded{
		background-color: $color-septenary;
		padding: 2.3rem 4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include borderRadius(2rem 2rem 0 0);

		.button{
			margin-bottom: 0;			
		}
		.left-title{
			display: flex;
			align-items: center;
			.button{margin-left: 2rem;}
		}
	}
	.content-box-rounded{
		padding: 3rem 4rem 4rem;
		@include borderRadius(0 0 2rem 2rem);

		.scroll-box{
			height: 8.7rem;
			overflow-y: auto;

			p{margin-bottom: .5rem;font-size: 1.5rem;font-weight: 500;}
		}
		table{margin-bottom: 0;width: 100%;}
		.row{
			/*.column{margin-bottom: 2rem;}*/
		}

		&.half-grey{
			position: relative;
			&:before{
				content:"";
				background: $color-septenary;
				height: 15rem;
				z-index: -1;
				@include position(absolute, 0 0 null 0);
			}
		}
	}
}
.button-disabled {
	cursor: default;
	pointer-events:none;				
}
.side-img{
	position: relative;
	padding-left: 6.2rem;
	min-height: 5rem;

	> img{
		position: absolute;
		top: -.3rem;
		left: 0;
	}
	.text-side-img{
		span{
			display: block;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 1.3rem;
			color:$color-primary; 
		}
		h5{
			font-size: 1.8rem;
			font-weight: 700;
			color: #093C81;
			margin-bottom: 0;
		}
	}

	&.v2{
		min-height: 0;
		display: flex;
		align-items: center;
		padding-left: 0;
		> div {
			display: flex;
			align-items: center; 
		}
		img{
			position: relative;
			top: auto;
			left: auto;
			margin-right: 1.2rem;
		}
		.text-side-img{
			span{
				text-transform: none;font-size: 1.5rem;
			}
		}
	}
}

.box-progress-rounded{
	margin-bottom: 1.4rem;

	.title-progress-rounded{
		color: $color-initial;
		background-color: $color-primary;
		padding: 1.3rem 3.5rem;
		text-align: center;
		font-weight: 700;
		font-size: 1.6rem;
		@include borderRadius(1rem 1rem 0 0);
	}
	.content-progress-rounded{
		padding: 2rem 3.5rem;
		background-color: $color-septenary;
		display: flex;
		justify-content: space-between;
		@include borderRadius(0 0 1rem 1rem);

		.col{
			span{
				display: block;
				margin: 0 auto .9rem;
				font-weight: 500;
				color: #555555;

				&.dot{margin-bottom: 0;}
			}
		}
	}
}

.dot{
	display: inline-block;
	vertical-align: middle;
	width: 1.4rem;
	height: 1.4rem;
	@include borderRadius(50%);

	&.dot-red{background-color: #C32C31}
	&.dot-green{background-color: #81BE27}
	&.dot-orange{background-color: #FFB400}
}

.note-progress{
	> span{
		margin-right: 4.2rem;
		.dot{
			margin-top: -.4rem;
			margin-right: .5rem;
		}
	}
}

.accordion-content{
	padding-bottom: 2.4rem;
	display: none;

	&.active{
		display: block;
	}
}
.accordion-button{
	text-transform: uppercase;
	display: inline-block;
	color: #093C81;
	font-weight: 700;
	position: relative;
	padding-right: 1.8rem;
	cursor: pointer;
	font-size: 1.4rem;

	&:after{
		content:"";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 1rem;
		background: url('../images/material/arrow-bottom.svg')no-repeat center;
	}

	&:hover{
		color: $color-secondary;
	}

	&.active{
		&:after{@include transform(rotate(180deg));}
	}
}

.slider-dashboard{
	.list-slide{
		position: relative;
		figure{
			img{
				width: 100%;
				vertical-align: top;
				@include borderRadius(1rem);
			}
		}
		figcaption{
			background: rgba(130,185,59,1);
			background: -moz-linear-gradient(-45deg, rgba(130,185,59,1) 0%, rgba(130,185,59,0.99) 17%, rgba(62,152,30,0.95) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(130,185,59,1)), color-stop(17%, rgba(130,185,59,0.99)), color-stop(100%, rgba(62,152,30,0.95)));
			background: -webkit-linear-gradient(-45deg, rgba(130,185,59,1) 0%, rgba(130,185,59,0.99) 17%, rgba(62,152,30,0.95) 100%);
			background: -o-linear-gradient(-45deg, rgba(130,185,59,1) 0%, rgba(130,185,59,0.99) 17%, rgba(62,152,30,0.95) 100%);
			background: -ms-linear-gradient(-45deg, rgba(130,185,59,1) 0%, rgba(130,185,59,0.99) 17%, rgba(62,152,30,0.95) 100%);
			background: linear-gradient(135deg, rgba(130,185,59,1) 0%, rgba(130,185,59,0.99) 17%, rgba(62,152,30,0.95) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82b93b', endColorstr='#3e981e', GradientType=1 );

			padding: 3.0rem 3.5rem 5.2rem;
			opacity: .95;
			width: 29rem;
			box-sizing: border-box;
			color: $color-initial;
			font-size: 2rem;
			font-weight: 500;
			text-align: right;
			@include position(absolute, null 6rem 0 null);
			@include borderRadius(4rem 0 0 0);
		}
	}

	.slick-dots{
		margin-bottom: 0;
		@include position(absolute, null 9.2rem .5rem null);

		li{
			padding-left: 0;
			display: inline-block;
			margin: 0 .4rem;
			height: 1rem;

			button{
				width: 1rem;
				height: 1rem;
				background: rgba(255,255,255,.63);
				font-size: 0;
				color: transparent;
				padding: 0;
				margin: 0;
				@include borderRadius(50%);
			}

			&.slick-active{
				button{background: $color-primary;}
			}
		}
	}
}

.row-claim{
	margin-bottom: 4rem;
	.column{
		margin-bottom: 0;
		color:#777; 
		&:hover,
		&.active {
			color:$color-secondary; 
			.side-img {
				.text-side-img {
					span {
						color:$color-secondary;
					}
				}
			}
		}
	}
}

.notif-inline{
	display: block;
	padding: 1.4rem 4rem 1.4rem 2.4rem;
	border: .1rem solid #C11616;
	border-radius: .4rem;
	background: #FFDEDE;
	color: #C32020;
	font-weight: 500;
	margin-bottom: 2rem;
	position: relative;

	.close-notif{
		width: 2rem;
		cursor: pointer;
		@include position(absolute, 0 2.2rem 0 null);

		&:before,&:after{
			content:"";
			height: .2rem;
			background: #C32020;
			border-radius: .2rem;
			cursor: pointer;
			@include transform(rotate(45deg));
			@include position(absolute, 50% 0 null 0);
		}
		&:after{
			@include transform(rotate(-45deg));
		}
	}

	&.green{
		color: #81BE27;
		border-color:#81BE27;
		background: #f3ffe1;
	}

	&.orange{
		color: #FFB400;
		border-color:#FFB400;
		background: #ffefc9;
	}
}

.row-white-rounded{
	margin-bottom: 4rem;
	> .column{
		margin-bottom: 0;
		> .inner-column{
			background: $color-initial;
			box-shadow: 0 1rem 2rem 0 rgba(0,0,0,0.1);
			border-radius: 1.2rem;
			padding: 2.7rem 3rem;
			height: 100%;

			h5{
				font-size: 2.2rem;
				font-weight: 700;
			}
			h6{font-weight: 700;}
		}

		&.no-padding{
			> .inner-column{
				padding: 0;
			}






			
		}
	}
}

.grey-text{color: #999999;margin-bottom: .5rem;}
.blue-text{color: $color-tertiary; margin-bottom: .8rem;}

.per-month{
	color: $color-tertiary;
	span{font-size: 1.4rem;font-weight: 700;color: #717171;}
}

.row-value{
	> .column{
		display: flex;
		align-items: flex-start;

		> img{
			width: 4rem;
			margin-right: 2rem;
		}

		.text-row-value{
			h6{font-weight: 700;margin-bottom: 0rem; color: #000;}
		}
		.blue-text {font-weight: bold;}
	}
}

.row-line-space{
	margin-left: 0;
	width: 100%;
	border-bottom: .1rem solid #F0F0F0;

	> .column{
		border-right: .1rem solid #F0F0F0;
		padding: 3rem 3rem 1rem;
		margin-bottom: 0;

		&:last-child{
			border-right: 0;
		}
	}
}

.row-bg-grey{
	margin-left: 0;
	width: 100%;

	> .column{
		background: #FAFAFA;
		padding: 2rem 3rem;

		&:first-child{
			border-radius: 0 0 0 1rem;
		}
		&:last-child{
			border-radius: 0 0 1rem 0;
		}
	}
}