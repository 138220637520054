@media all and (max-width: 767px ) {
    .sign-layout{
        .wrapper{margin: 0 15px; padding-top: 168px;}
        .caption{
            position: relative; width: 100%; bottom: unset; left: unset; top: unset; right: unset; align-self: unset; text-align: center;
            p{display: none;}
            h2{font-size: 1.8rem;}
        }
        .row{
            display: block; justify-content: space-between;
            .form-login{width: 100%; max-width: unset; padding: 0 25px 60px;}
            &.row-center{
                .column-50{float: left;}
                .forgot-link{float: right;}
            }
            /*.column-48{float: left; width: 100%; max-width: unset;}*/
        }
        .tab-login{
            margin: 0 -50px 15px;
            a{
                font-size: 1.5rem; height: 6rem; line-height: 6rem;
                img{width: 22px;}
            }
        }
        .form{
            label{font-size: 1.3rem; margin-bottom: 5px;}
            .toggle-password {
                right: 15px; 
            }            
        }
        form {
            .row {
                display: flex; justify-content: space-between; flex-direction: row; 
                margin-bottom: 10px;  
                margin-left: 0; 
                width: 100%;    
                .column {padding: 0;}           
                .column-48 {
                    float: none; 
                }    
                &.row-btn {
                    margin-top:0; 
                }            
            }

        }
        input[type='email'], input[type='number'], input[type='password'], input[type='search'], input[type='tel'], input[type='text'], input[type='url'], textarea, select{font-size: 1.4rem;}
        .button, button, input[type='button'], input[type='reset'], input[type='submit']{font-size: 1.3rem;}
        input[type='password'] {
            padding-right: 40px;
        }
        .sign-radio{
            height: auto; position: relative; margin-bottom: 20px;
            figure{
                width: 34px; height: 34px; margin: 23px 13px; margin: 15px 13px;
                img{width: 18px;}
            }
            span{position: absolute; top: 50%; transform: translateY(-50%); width: 50px; left: 50px;}
        }
        .foot{font-size: 1.2rem; margin-top: 1rem;}
    }
    .wrap-popup{
        &.otp-popup{
            .box-popup{
                width: auto; margin: 5% 25px; padding: 70px 30px;
                h4{font-size: 1.8rem;}
                p{font-size: 1.6rem;}
                .otp{
                    margin: 15px 20px;
                    input{
                        width: 30px; height: 30px; margin-right: 5px; font-size: 1.5rem;
                        &:last-child{margin-right: 0;}
                    }
                }
                button{width: 100%;font-size: 1.4rem;}
            }
        }
    }
}