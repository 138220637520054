@media all and (max-width: 767px ) {
    .title-tab{
        figure{max-width: 26px; margin-right: 25px;}
        h5{font-size: 1.6rem; line-height: 1.9rem; margin-bottom: 7px;}
        p{font-size: 1.5rem; line-height: 1.9rem; margin-bottom: 0;}
    }
    .max-limit {
    	flex-direction: column; 
    	h6 {
    		width: 100%; margin-bottom: .5rem; 
    	}
    	h5 {
    		width: 100%; margin: 0; 
    	}
    }
}