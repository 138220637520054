header.head-misc{
    text-align:center;
    padding:80px 0 0px;
}

.misc-layout{
    h3{
        text-transform:capitalize;
        margin-bottom:1rem;
    }
    h5{
        margin-bottom:3rem;
    }
    figure{
        margin-bottom:3rem;
    }

}