@media all and (max-width: 1280px) {
    .sign-layout{
        .wrapper{padding: 105px 0 60px; margin: 0 60px;}
        .form-login{
            &.column.column-35{
                max-width: unset; flex: unset;
            }
        }
        .caption{
            left: 6rem; width: 40rem;
            h2{font-size: 2.8rem;}
            p{font-size: 2rem;}
        }
        .tab-login{
            a{font-size: 1.8rem; height: 70px; line-height: 7rem;}     
        }
    }
}
@media all and (max-width: 1023px) {
    .sign-layout{
        .wrapper{margin: 0 60px; padding-top: 35rem;}
        .caption{
            position: relative; width: 100%; bottom: unset; left: unset; top: unset; right: unset; align-self: unset; margin-bottom: 4rem;
            h2{font-size: 2rem; margin-bottom: 0;}
        }
        .row{
            display: block;
            .form-login{width: 100%; max-width: unset; padding: 0 25px 60px;}
            &.row-center{
                .column-50{float: left;}
                .forgot-link{float: right;}
            }
            .column-48{float: left; margin-bottom: 2rem;}
        }
        .tab-login{
            margin: 0 -50px 15px;
            a{
                font-size: 1.5rem; height: 6rem; line-height: 6rem;
                img{width: 22px;}
            }
        }
        .form{
            label{font-size: 1.3rem;}
        }
        .foot{font-size: 1.2rem; margin-top: 6rem;}
    }
    .wrap-popup{
        &.otp-popup{
            .box-popup{
                width: auto; margin: 5% 25px; padding: 70px 30px;
                h4{font-size: 1.8rem;}
                p{font-size: 1.6rem;}
                .otp{
                    margin: 15px 20px;
                    input{
                        width: 40px; height: 40px; margin-right: 5px;
                        &:last-child{margin-right: 0;}
                    }
                }
                button{width: 100%;}
            }
        }
    }
}