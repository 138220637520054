.form.account-form{
    border-radius:10px;
}
.title-account{
    display:flex;
    align-items:center;
    margin-bottom:31px;
    h5{margin-bottom:0;}
    a{
        margin-left:auto;
        line-heihgt:20px;
        color:#505050;
        padding:0 0 0 30px;
        background:url('../images/material/ico-lock.svg') no-repeat left center;
        &:hover{
            color:$color-secondary;
        }
    }
}