@media all and (max-width: 1280px) {
    .box-rounded{
        .title-box-rounded{
            padding: 3rem; padding-bottom: 0;
            .button{
                text-transform: none;
            }
        }
        .content-box-rounded{
            padding: 3rem;
        }
    }
    .row-white-rounde{
        > .column{
            .inner-column{padding: 3rem;}
        }

    }
    .grey-text{font-size: 1.5rem;}
    .row-value{
        > .column{
            > img{margin-right: 1rem;}
        }
    }
    .slider-dashboard{
        .list-slide{
            figcaption{
                font-size: 1.8rem;
            }
        }
    }
}
@media all and (max-width: 1023px) {
    
}